/*
 * 업무구분   : 공통
 * 화면 명    : MSPBC007P
 * 화면 설명  : 유파트너 공지사항 게시 상세 팝업
 */
<template>
  <ur-page-container class="msp" title="게시" :show-title="true" type="subpage" @on-header-left-click="fn_ClickBtnPrevious">
    <ur-box-container direction="column" alignV="start" class="msp-bc-wrap mb50">
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
          <div class="alarm-title">
            <p>
              <span v-html='lv_resultDtlData.bltnTitl'></span>
            </p>
          </div>

          <div class="alarm-detail-txt">
            <ur-box-container alignV="start" componentid="" direction="column" class="date">
              <div class="full font14r">
                <span v-if="lv_resultDtlData.bltnStrYmd || lv_resultDtlData.bltnEndYmd"><span>{{lv_resultDtlData.bltnStrYmd}}</span> ~ <span>{{lv_resultDtlData.bltnEndYmd}}</span></span>
                <span v-if="lv_resultDtlData.userNm" class="fr">작성자<span class="ml5 ns-ftcr-darkgray">{{lv_resultDtlData.userNm}}</span></span>
              </div>
            </ur-box-container>
            <div>
              <p class="detail-txt html_content">
                <span v-html='lv_resultDtlData.bltnCntnt'></span>
              </p>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" v-if="this.lv_resultDtlData.attacList.length" componentid="" direction="column" class="ns-btn-relative noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round file" @click="fn_OpenAttachPopup">
              첨부파일 총 {{lv_resultDtlData.attacList.length}}건 ({{lv_resultDtlData.attacListTotalSize}}MB)
              <mo-icon>download</mo-icon>
            </mo-button>
          </div>
        </ur-box-container>
    </ur-box-container>

    <!-- Popup001 -->
    <mo-bottom-sheet ref="attachPopup" close-btn class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        첨부파일
      </template>
       
      <div class="content-area">
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-info-list bs-list">
          <mo-list :list-data="lv_resultDtlData.attacList">
            <template #list-item="{item}">
              <mo-list-item>
                <div class="list-item__contents" @click="fn_BtnESaveFile(item)">
                  <div class="list-item__contents__info">
                    <div class="info_box">
                      <span>{{item.apndFileCntnt}}</span>
                      <mo-button componentid="" color="primary" shape="border" class="ns-btn-round h28 blueline fr">보기</mo-button>
                    </div>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>

        </ur-box-container>
      </div>
    </mo-bottom-sheet>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPBC007P',
  screenId: 'MSPBC007P',
  components: {},
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    iapInqrKeyVal: {
      type: String,
      default: ''
    },
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // lv_isShowEmpty: true,
      lv_resultDtlData: {userNm: '', bltnStrYmd: '', bltnEndYmd: '', bltnTitl: '', bltnCntnt: '', attacList: [], attacListTotalSize: 0},
    };
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {},
  mounted() {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.fn_LoadAnuncDtl()
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_LoadAnuncDtl
    * 설명       : 공지사항 상세내용을 조회한다.
    ******************************************************************************/
    fn_LoadAnuncDtl() {
      if (!this.fn_WifiNetCheck()) return
      // this.lv_isShowEmpty = !this.iapInqrKeyVal
      let pParams = { bltnpId: this.iapInqrKeyVal }
      const trnstId = 'txTSSBC11S2'
      const auth = 'S'
      // for (var i = 0; this.lv_resultData.length > i; i++) {
      //   if (this.lv_resultData[i].iapInqrKeyVal === this.iapInqrKeyVal) {
      //     this.lv_resultData[i].isNew = false
      //   }
      // }
      this.getStore('progress').dispatch('FULL')
      this.post(this, pParams, trnstId, auth)
        .then((response) => {
          // console.log('값 체크 = ' + response)
          // this.lv_isShowEmpty = false
          this.lv_resultDtlData.userNm = response.body.bCAnucMngDtlSVO[0].wrtrNm
          this.lv_resultDtlData.bltnStrYmd = response.body.bCAnucMngDtlSVO[0].avlStrYmd
          this.lv_resultDtlData.bltnEndYmd = response.body.bCAnucMngDtlSVO[0].avlEndYmd
          this.lv_resultDtlData.bltnTitl = response.body.bCAnucMngDtlSVO[0].bltnpTitl
          
          if (process.env.NODE_ENV === 'production') {
            console.log('운영계 호출')
            this.lv_resultDtlData.bltnCntnt = response.body.bCAnucMngDtlSVO[0]?.bltnpCntnt?.replaceAll('http://ap.xp.portal.samsunglife.kr:41000', 'http://u-channel.samsunglife.com')
          } else if (process.env.NODE_ENV === 'stage' || process.env.NODE_ENV === 'stage2') {
            console.log('검증계 호출')
            this.lv_resultDtlData.bltnCntnt = response.body.bCAnucMngDtlSVO[0]?.bltnpCntnt?.replaceAll('http://ap1.xv.portal.samsunglife.kr:40001', 'http://u-channel.v.samsunglife.kr:9000')
          }

          if (response.body.bCAnucMngDtlFileSVO.length > 0) {
            this.lv_resultDtlData.attacList = []
            this.lv_resultDtlData.attacListTotalSize = 0

            response.body.bCAnucMngDtlFileSVO.forEach(arr => {
              this.lv_resultDtlData.attacListTotalSize += parseFloat((arr.srcFileSize / Math.pow(2, 20)).toFixed(2))
              this.lv_resultDtlData.attacList.push(arr)
            })
          } else {
            this.lv_resultDtlData.attacList = []
            this.lv_resultDtlData.attacListTotalSize = 0
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // N 뱃지 새로고침용 이벤트 TSSBC100M 에서 핸들 처리
      // window.vue.getInstance('vue').$emit('reloadNoticeEvent')
    },
    /******************************************************************************
    * Function명 : fn_BtnESaveFile
    * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
    ******************************************************************************/
    fn_BtnESaveFile (obj) {
      if (!this.fn_WifiNetCheck()) return
      window.vue.getStore('progress').dispatch('SHOW')
      this.fn_FdpSaveFile(obj).then(resolve => {
        console.log('파일 다운로드 성공 : ' + resolve)
        obj.resolveFileNm = resolve
        this.fn_FdpOpenFile(obj).then(resolve => {
          console.log('파일 오픈 성공 : ' + resolve)
        }, reject => {
          console.log('파일 오픈 실패 : ' + reject)
        }).catch(error => {
          console.log(new Error(error))
        }).finally(() => {
          window.vue.getStore('progress').dispatch('HIDE')
        })
      }, reject => {
        window.vue.getStore('progress').dispatch('HIDE')
        console.log('파일 다운로드 실패 : ' + reject)
      }).catch(error => {
        window.vue.getStore('progress').dispatch('HIDE')
        console.log(new Error(error))
      })
    },
    /******************************************************************************
    * Function명 : fn_FdpSaveFile
    * 설명       : 첨부파일을 저장한다.
    ******************************************************************************/
    fn_FdpSaveFile (obj) {
      return new Promise((resolve, reject) => {
        window.fdpbridge.exec('downloadPlugin', {
          path: 'NOLTR_APND_DOWNLOAD_DIR',
          filename: obj.apndFileRalStoreNm, // src Directory + fileName
          type: obj.apndFileCntnt.substring(obj.apndFileCntnt.lastIndexOf('.') + 1, obj.apndFileCntnt.length),
          localpath: '/sli/tss/comm/notice', // local Directory
          localfilename: obj.apndFileCntnt, // local fileName
          rename: 'Y'
        }, (result) => {
          console.log('파일 다운로드 성공 콜백 : ' + result.data)
          resolve(result.data)
        }, (result) => {
          console.log('파일 다운로드 실패 콜백 : ' + result.data)
          reject(result.data)
        })
      })
    },
    /******************************************************************************
    * Function명 : fn_FdpOpenFile
    * 설명       : 다운로드 파일을 오픈한다.
    ******************************************************************************/
    fn_FdpOpenFile (obj) {
      return new Promise((resolve, reject) => {
        window.fdpbridge.exec('intentViewerPlugin', {
          path: '/sli/tss/comm/notice',
          filename: obj.resolveFileNm,
          type: obj.apndFileCntnt.substring(obj.apndFileCntnt.lastIndexOf('.') + 1, obj.apndFileCntnt.length)
        }, (result) => {
          console.log('파일 오픈 성공 콜백 : ' + result.data)
          resolve(result.data)
        }, (result) => {
          console.log('파일 오픈 실패 콜백 : ' + result.data)
          reject(result.data)
        })
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenAttachPopup
     * 설명       : 첨부파일 팝업 열기
     ******************************************************************************/
    fn_OpenAttachPopup() {
       this.$refs.attachPopup.open()
    },
    /******************************************************************************
    * Function명 : fn_WifiNetCheck
    * 설명       : 사내망 체크
    ******************************************************************************/
    fn_WifiNetCheck () {
      if (!this.$MenuManager.fn_GetInternalNetwork()) {
        window.vue.getStore('confirm').dispatch('ADD', '사내Wifi망에서만 접속 가능합니다.')
        this.fn_ClickBtnPrevious()
        return false
      }
      return true
    },
    /******************************************************************************
     * Function명 : fn_ClickBtnPrevious
     * 설명       : 팝업 닫기
     ******************************************************************************/
    fn_ClickBtnPrevious () {
      this.$emit('onPopupClose')
    },
  },
};
</script>
<style scoped>
::v-deep .html_content span img {
  width: 100% !important;
  height: auto !important; 
}
</style>